<template>
  <div class="upload">
    <div class="btn">
      <span @click="$router.back()">返回</span>
      <h1>人脸信息采集</h1>
      <span></span>
    </div>

    <div class="main">
      <van-uploader
        v-model="fileList"
        :after-read="onRead"
        :max-count="1"
        accept="image/*"
        :preview-size="size"
        capture="camera"
      >
      </van-uploader>
    </div>

    <div class="btn-bottom">
      <div v-if="!isFile">
        <button class="btn-black" @click="fileUpload">上传人脸信息</button>
      </div>
      <div v-else>
        <p class="upload-text">人脸信息已采集！点击右上角删除可重新采集</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import { Toast } from "vant";
import { Uploader } from "vant";
import { imageFace } from "@api/user";
Vue.use( Toast);
Vue.use( Uploader);
export default {
  data() {
    return {
      uid: '',
      realname: "",
      status:0,
      fileList: [],
      isFile: false,
      headerImage: null,
      picValue: null,
      file: null,
      files: {
        name: "",
        type: "",
      },
      size: '6.9rem'
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  watch: {
    'fileList'(val) {
      val.length == 0 ? this.isFile = false : ''
    }
  },
  created() {
    if (this.userInfo){
        this.realname = this.userInfo.real_name;
        this.uid = this.userInfo.uid;
        this.status = this.userInfo.face_status;
        if(this.userInfo.photo) {
            this.isFile = true
            this.fileList = [{url: this.userInfo.photo}];
        } else {
            this.isFile = false
        }
    }else{
        that.$dialog.error('获取数据失败！');
    }
    // this.initEvent();
  },
  methods: {
    // 组件方法 获取 流
    async onRead(file) {
      this.$dialog.loading.open('处理中...')
      // console.log(file);
      // console.log(file.file);
      this.files.name = file.file.name; // 获取文件名
      this.files.type = file.file.type; // 获取类型
      this.picValue = file.file; // 文件流
      this.imgPreview(this.picValue);
    },
    // 处理图片
    imgPreview(file) {
      let self = this;
      let Orientation;
      // //去获取拍照时的信息，解决拍出来的照片旋转问题
      // Exif.getData(file, function () {
      //   Orientation = Exif.getTag(this, "Orientation");
      // });      
      // 看支持不支持FileReader
      if (!file || !window.FileReader) return;
      if (/^image/.test(file.type)) {
        // 创建一个reader
        let reader = new FileReader();
        // 将图片2将转成 base64 格式
        reader.readAsDataURL(file);
        // 读取成功后的回调
        reader.onloadend = function () {
          // console.log(this.result);
          let result = this.result;
          let img = new Image();
          img.src = result;
          //判断图片是否大于500K,是就直接上传，反之压缩图片
          if (this.result.length <= 500 * 1024) {
            self.headerImage = this.result;
            self.postImg();
          } else {
            img.onload = function () {
              let data = self.compress(img, Orientation);
              self.headerImage = data;
              self.postImg();
            };
          }
        };
      }
    },
    // 压缩图片
    compress(img, Orientation) {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      //瓦片canvas
      let tCanvas = document.createElement("canvas");
      let tctx = tCanvas.getContext("2d");
      // let initSize = img.src.length;
      let width = img.width;
      let height = img.height;
      //如果图片大于四百万像素，计算压缩比并将大小压至400万以下
      let ratio;
      if ((ratio = (width * height) / 4000000) > 1) {
        // console.log("大于400万像素");
        ratio = Math.sqrt(ratio);
        width /= ratio;
        height /= ratio;
      } else {
        ratio = 1;
      }
      canvas.width = width;
      canvas.height = height;
      //    铺底色
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      //如果图片像素大于100万则使用瓦片绘制
      let count;
      if ((count = (width * height) / 1000000) > 1) {
        // console.log("超过100W像素");
        count = ~~(Math.sqrt(count) + 1); //计算要分成多少块瓦片
        //      计算每块瓦片的宽和高
        let nw = ~~(width / count);
        let nh = ~~(height / count);
        tCanvas.width = nw;
        tCanvas.height = nh;
        for (let i = 0; i < count; i++) {
          for (let j = 0; j < count; j++) {
            tctx.drawImage(
              img,
              i * nw * ratio,
              j * nh * ratio,
              nw * ratio,
              nh * ratio,
              0,
              0,
              nw,
              nh
            );
            ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
          }
        }
      } else {
        ctx.drawImage(img, 0, 0, width, height);
      }
      //修复ios上传图片的时候 被旋转的问题
      if (Orientation != "" && Orientation != 1) {
        switch (Orientation) {
          case 6: //需要顺时针（向左）90度旋转
            this.rotateImg(img, "left", canvas);
            break;
          case 8: //需要逆时针（向右）90度旋转
            this.rotateImg(img, "right", canvas);
            break;
          case 3: //需要180度旋转
            this.rotateImg(img, "right", canvas); //转两次
            this.rotateImg(img, "right", canvas);
            break;
        }
      }
      //进行最小压缩
      let ndata = canvas.toDataURL("image/jpeg", 0.25);
      tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
      this.$dialog.loading.close()
      return ndata;
    },
    // 旋转图片
    rotateImg(img, direction, canvas) {
      //最小与最大旋转方向，图片旋转4次后回到原方向
      const min_step = 0;
      const max_step = 3;
      if (img == null) return;
      //img的高度和宽度不能在img元素隐藏后获取，否则会出错
      let height = img.height;
      let width = img.width;
      let step = 2;
      if (step == null) {
        step = min_step;
      }
      if (direction == "right") {
        step++;
        //旋转到原位置，即超过最大值
        step > max_step && (step = min_step);
      } else {
        step--;
        step < min_step && (step = max_step);
      }
      //旋转角度以弧度值为参数
      let degree = (step * 90 * Math.PI) / 180;
      let ctx = canvas.getContext("2d");
      switch (step) {
        case 0:
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0);
          break;
        case 1:
          canvas.width = height;
          canvas.height = width;
          ctx.rotate(degree);
          ctx.drawImage(img, 0, -height);
          break;
        case 2:
          canvas.width = width;
          canvas.height = height;
          ctx.rotate(degree);
          ctx.drawImage(img, -width, -height);
          break;
        case 3:
          canvas.width = height;
          canvas.height = width;
          ctx.rotate(degree);
          ctx.drawImage(img, -width, 0);
          break;
      }
    },
    //将base64转换为文件
    dataURLtoFile(dataurl) {
      var arr = dataurl.split(","),
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], this.files.name, {
        type: this.files.type,
      });
    },
    // 生成图片文件流
    async postImg() {
      this.file = this.dataURLtoFile(this.headerImage);
      this.$dialog.loading.close()
    },

    // 上传
    fileUpload() {
      let that = this

      if(!that.file && !that.isFile) {
        that.$dialog.error('请拍照上传人脸照片');
        return false
      }
      if(that.isFile) {
        that.$dialog.error('请重新上传人脸照片');
        return false
      }
      let formData = new FormData()
      let fileName = that.uid+'_'+that.realname+'.jpg'
      if(fileName.indexOf('_') == -1) {
        fileName = that.uid+'_'+that.realname+'.jpg'
      }
      formData.append('file', that.file, fileName)
      imageFace(formData).then(res => {
        that.$dialog.success(res.msg)
        that.$router.go(0);
      }).catch(err => {
        that.$dialog.error(err.msg)
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.upload {
  .btn {
    height: 1rem;
    line-height: 1rem;
    text-align: center;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #DDd;
    background: white;
    h1 {
      font-size: .3rem;
      font-weight: 500;
    }
    span {
      font-size: .28rem;
      width: 1.2rem;
      text-align: center;
      color: #2291f8;
    }
  }

  .main {
    padding: .2rem 0;
    .input {
      height: .8rem;
      line-height: .8rem;
      display: flex;
      justify-content: space-between;
      margin: .2rem 0;
      padding: 0 .3rem;
      background: white;
      input {
        width: 5rem;
      }
    }
    .van-uploader {
      margin: .2rem 0 0 .3rem;
    }
  }
   .personDialog {
    .box {
      padding: 0.3rem 0.3rem 0.5rem 0.5rem;
      van-divider {
        color: #1989fa;
        display: block;
        text-align: center;
        margin: .2rem 0 .4rem 0;
      }
      .input {
        display: flex;
        justify-content: space-between;
        margin-top: 0.2rem;
        font-size: 0.28rem;
        line-height: 0.65rem;
        span {
          color: #666;
          width: 1.5rem;
        }
        input {
          font-size: 0.26rem;
          width: 4.5rem;
          border: 1px solid #ddd;
          border-radius: 0.08rem;
          height: 0.65rem;
          padding-left: 0.2rem;
        }
      }
      .btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 0.3rem;
        border: none;
        button {
          height: 0.72rem;
          width: 2.4rem;
          text-align: center;
          border-radius: 0.08rem;
          color: white;
          background: #26a2ff;
        }
      }
    }
  }
  .upload-text {
    text-align: center;
    color: red;
    font-size: .3rem;
  }
}
</style>